import { Button, TextField, Typography } from "@mui/material"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SvgIcon } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as Logo } from '../logo-white.svg';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, functions } from "../firebase";
import { getDoc, doc, collection } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import Results from "./results";

const Creator = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [data, setData] = useState(null);
  const { promptId } = useParams();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const profileRef = doc(db, 'profiles', user.uid)
        const profile = (await getDoc(profileRef)).data();
        if (profile.role === 'admin') {
          setUser(user);
        }
      }
    })
  }, []);

  const generateOptions = async () => {
    setIsLoading(false)
    const getOptions = httpsCallable(functions, 'analyzePrompt');
    const res = await getOptions({ prompt, conversationId: false, lat: 51.9015318, lng: 4.4874205, visible: false });
    const data = res.data;
    setData(data);
    navigate(`${data.promptId}`)
    setIsLoading(false)
  }

  const generateOptionsThroughExpress = async () => {
    setIsLoading(true)
    const id = crypto.randomUUID();
    const convId = doc(collection(db, 'prompts')).id;

    // const res = await fetch("https://travel-app-express-api-96642599925.us-central1.run.app/analyzePrompt", {
      const res = await fetch("https://travel-app-express-api-8587184342.us-central1.run.app/analyzePrompt", {
      method: "POST",
      body: JSON.stringify({
        uid: user.uid, 
        prompt: `${prompt}, btw I am in Rotterdam`, 
        convId, 
        id, 
        rawPrompt: prompt, 
        visible: true,
        messages: [],
        parent: true
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });
    setIsLoading(false)
    navigate(`/admin/creator/${id}`)
  }

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      generateOptionsThroughExpress();
      // generateOptions();
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{display: "flex", alignItems: "start"}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "2em", verticalAlign: "start" }}>
              <Link style={{ color: "#fff", textDecoration: 'none', display: 'flex', verticalAlign: "start", flexDirection: 'row'}} to="/" >
                <Avatar sx={{ m: 1, bgcolor: 'black', margin: "-7px 0 0 0" }}>
                  <SvgIcon><Logo /></SvgIcon>
                </Avatar>
                <Typography component="h1" variant="h5">
                  Tripcompanion
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        {user ? <Grid flexDirection={"column"} item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={0} square sx={{display: "flex", alignItems: "start"}}>
        <TextField multiline={true} fullWidth={true} onChange={ handlePromptChange } onKeyDown={ handleKeyDown } />
          <Button disabled={!prompt} variant="contained" onClick={() => generateOptionsThroughExpress()}>{isLoading ? '...loading' : 'Generate suggestions'}</Button>
        </Grid> : null }
        {data || promptId ? <Results queryParams={{ promptId: data?.promptId || promptId }} /> : null}
      </Grid>
  )
}

export default Creator