import { Typography } from "@mui/material"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SvgIcon } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as Logo } from './logo-white.svg';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "2em", verticalAlign: "start" }}>
            <Link style={{ color: "#fff", textDecoration: 'none', display: 'flex', verticalAlign: "start", flexDirection: 'row'}} to="/" >
              <Avatar sx={{ m: 1, bgcolor: 'black', margin: "-7px 0 0 0" }}>
                <SvgIcon><Logo /></SvgIcon>
              </Avatar>
              <Typography component="h1" variant="h5">
                Tripcompanion
              </Typography>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}></Grid>
    </Grid>
  )
}

export default ResetPassword;