import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, query, where, orderBy, getDocs, getDoc, doc, limit, setDoc, serverTimestamp } from "firebase/firestore";
import { Avatar, Badge, List, ListItem, ListItemAvatar, ListItemText, TextField, Grid, Button } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail'
import { useParams } from "react-router-dom";


const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [message, setMessage] = useState('');
  const { uid } = useParams();
  
  useEffect(() => {
    const getFeedbacks = async () => {
      const feedbackQuery = query(collection(db, 'feedbacks'), where('user.profileId', '==', uid))
      const feedbackSnapshot = await getDocs(feedbackQuery);
      console.log(feedbackSnapshot.docs);
      setFeedbacks(feedbackSnapshot.docs.map(doc => {
        console.log(doc.data())
        return doc.data()
      }));
    }
    if (uid) {
      getFeedbacks();
    }
  }, [uid]);

  const handleMessageChange = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  }

  const sendFeedback = async (event) => {
    event.preventDefault();
    const userDoc = doc(db, 'profiles', uid)
    const user = (await getDoc(userDoc)).data();
    
    const id = crypto.randomUUID();
    const docRef = doc(db, 'feedbacks', id);
    await setDoc(docRef, {
      user: {
        profileId: user.id,
        name: user.name,
        email: user.email
      },
      id,
      read: false,
      message,
      createdAt: new Date().toISOString(),
      from: 'tripcompanion',
      timestamp: serverTimestamp()
    });
  }

  return (
    <Grid container sx={{ flexDirection: 'column' }}>
      <Grid sx={{ height: '50vh', displa: 'flex', overflow: 'scroll' }} item>
        <List>
          {feedbacks && feedbacks.length ? feedbacks.map((feedback, i) => {
            return <ListItem key={i}>
              <ListItemText>
                {feedback.message}
              </ListItemText>
            </ListItem>
          }) : null}
        </List>
      </Grid>
      <Grid item>
        <TextField fullWidth={true} onChange={(e) => handleMessageChange(e)}></TextField>
        <Button onClick={(e) => sendFeedback(e)}>Send</Button>
      </Grid>
    </Grid>
  )
}

export default Feedback;