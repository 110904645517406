import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { Avatar, Badge, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail'
import { useParams } from "react-router-dom";


const Prompts = () => {
  const [prompts, setPrompts] = useState([]);
  const { uid } = useParams();
  useEffect(() => {
    const getPrompts = async () => {
      const promptsQuery = query(collection(db, 'prompts'), where('uid', '==', uid))
      const promptsSnapshot = await getDocs(promptsQuery);

      setPrompts(promptsSnapshot.docs.map(doc => {
        return doc.data()
      }))
    }
    if (uid) {
      getPrompts();
    }
  }, [uid]);

  return (
    <List>
      {prompts && prompts.length ? prompts.map((prompt, i) => {
        return <ListItem key={i}>
          <ListItemText>
            {prompt.messages[0]?.prompt}
          </ListItemText>
        </ListItem>
      }) : null}
    </List>
  )
}

export default Prompts;