import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Privacy from './Privacy';
import Terms from './Terms';
import ResetPassword from './resetPassword';
import ForgotPassword from './forgotPassword';
import Admin from './Admin'
import QR from './QR';
import Creator from './admin/creator';

const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#fff"
    },
    info: {
      main: "#fff"
    }
  },
  typography: {
    fontFamily: ['Euclid Circular A']
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/admin/:uid",
    element: <Admin />
  },
  {
    path: "/details",
    element: <App />,
  },
  {
    path: "/trip-details",
    element: <App />,
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  ,
  {
    path: "/terms",
    element: <Terms />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/qr",
    element: <QR />
  },
  {
    path: "/admin/creator/:promptId?",
    element: <Creator />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <style>
    @import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
  </style>
    <ThemeProvider theme={defaultTheme}>
    <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
