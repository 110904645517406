import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { Avatar, Badge, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail'
import { useNavigate } from "react-router-dom";


const Users = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getUsers = async () => {
      const usersQuery = query(collection(db, 'profiles'))
      const usersSnapshot = await getDocs(usersQuery);
      const feedbacksQuery = query(collection(db, 'feedbacks'))
      const feedbacksSnapshot = await getDocs(feedbacksQuery);
      const usersWithFeedback = usersSnapshot.docs.map(doc => {
        const user = doc.data();
        
        const userObject = {
          ...user,
          feedbackExists: getFeedbacksForUsers(user.id, feedbacksSnapshot.docs)?.data()
        }
        // console.log(userObject);
        return userObject
      });
      setUsers(usersWithFeedback.sort((a, b) => {
        if (a.feedbackExists && b.feedbackExists) return 0;
        if (a.feedbackExists && !b.feedbackExists) return -1;
        if (!a.feedbackExists && b.feedbackExists) return 1;
      }))
    }
    getUsers();
  }, []);

  const getFeedbacksForUsers = (userId, feedbacks) => {
    return feedbacks.find(doc => {
      if (doc && doc.data().user.profileId === userId) {
       return doc.data()
      }
    });
  }

  return (
    <List>
      {console.log(users.map(user => user.feedbackExists))}
      {users && users.length ? users.map((user, i) => {
        return <ListItem sx={{ cursor: 'pointer' }} onClick={() => navigate(`/admin/${user.id}`)} key={i}>
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={user.picture} />
            {user.feedbackExists ? <Badge badgeContent={'!'} color="primary">
              <MailIcon color="action" />
            </Badge> : null}
          </ListItemAvatar>
          <ListItemText>
            {user.name}
          </ListItemText>
        </ListItem>
      }) : null}
    </List>
  )
}

export default Users;