import { Typography } from "@mui/material"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SvgIcon } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as Logo } from './logo-white.svg';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "2em", verticalAlign: "start" }}>
              <Link style={{ color: "#fff", textDecoration: 'none', display: 'flex', verticalAlign: "start", flexDirection: 'row'}} to="/" >
                <Avatar sx={{ m: 1, bgcolor: 'black', margin: "-7px 0 0 0" }}>
                  <SvgIcon><Logo /></SvgIcon>
                </Avatar>
                <Typography component="h1" variant="h5">
                  Tripcompanion
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
          <Typography variant="body2" style={{ margin: "0 2em 4em" }} color="text.secondary">
          <p>Effective Date: 26 September 2023</p>

<p>Welcome to TripCompanion app ("App"). These Terms and Conditions ("Terms") govern your use of the App and the services provided through it. By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App.</p>

<p>1. User Eligibility</p>

<p>1.1 If you are using this App on behalf of a company or organisation, you represent and warrant that you have the authority to bind that entity to these Terms.</p>

<p>2. Registration and Account</p>

<p>2.1 To access certain features of the App, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to update your information to keep it accurate and current.</p>

<p>2.2 You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorised use of your account.</p>

<p>3. App Usage</p>

<p>3.1 The App is intended for personal, non-commercial use. You may not use the App for any unlawful or unauthorised purpose.</p>

<p>3.2 You agree to comply with all applicable laws and regulations while using the App.</p>

<p>4. Booking and Payments</p>

<p>4.1 The App gives you travel inspirations based on the input you search for.</p>

<p>4.2 The App follows a Meta model, where it shows you inspirations and travel products (experiences, tours, activities, hotels, cafes, restaurants, flights, rental cars) that you can see but cannot book on our app. The app will redirect you to the websites of travel providers who provide those products, where you can make the booking on their website.</p>

<p>5. Cancellation and Refunds</p>

<p>5.1 Cancellation and refund policies for bookings made through recommendations provided in this App may vary depending on the travel service providers to which this app redirects you to. It is your responsibility to review and understand these policies of the travel service providers before making a booking.</p>

<p>5.2 Trip Companion is not responsible for any refunds or cancellations, as these are subject to the terms and conditions of the travel service provider.</p>

<p>6. Content and User Contributions</p>

<p>6.1 Within Trip Companion, you have the opportunity to submit content to the App, such as reviews and comments. You are solely responsible for the content you submit, and you agree not to post any content that is unlawful, defamatory, or violates the rights of others.</p>

<p>6.2 By submitting content to the App, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable licence to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content.</p>

<p>7. Privacy</p>

<p>7.1 Your use of the App is governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand how we collect, use, and disclose your personal information.</p>

<p>8. Termination</p>

<p>8.1 We reserve the right to suspend or terminate your account and access to the App at our sole discretion, without notice, for any reason, including if you violate these Terms.</p>

<p>9. Disclaimer of Warranties</p>

<p>9.1 The App is provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the accuracy, reliability, or availability of the App.</p>

<p>10. Limitation of Liability</p>

<p>10.1 We shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the App.</p>

<p>11. Governing Law and Jurisdiction</p>

<p>11.1 These Terms are governed by the local Dutch Laws. Any legal action or proceeding arising out of or related to these Terms shall be filed in the courts of the Netherlands.</p>

<p>12. Changes to Terms</p>

<p>12.1 We reserve the right to update or modify these Terms at any time without prior notice. Your continued use of the App after such changes constitutes your acceptance of the new Terms.</p>

<p>13. Contact Information</p>

<p>13.1 If you have any questions or concerns about these Terms, please contact us at info-support@tripcompanion.io.</p>

<p>By using the App, you acknowledge that you have read, understood, and agreed to these Terms and our Privacy Policy.</p>


          </Typography>
        </Grid>
      </Grid>
  )
}

export default Terms