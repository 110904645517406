import * as React from 'react';
import { useState } from 'react'; 
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Image } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Snackbar, SvgIcon } from '@mui/material';
import { ReactComponent as LogoWhite } from './logo.svg'
import { ReactComponent as Logo } from './logo-white.svg'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Countdown from 'react-countdown';
import { db } from './firebase';
import { collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import iPhone from './for-mobile-mockup-portrait.png';
import { ReactComponent as AppStore } from './AppStore.svg';
import { ReactComponent as GooglePlay } from './GooglePlay.svg';

function Copyright(props) {
  const theme = useTheme();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ marginTop: 0 }}>
      {'Copyright © '}
      tripcompanion {' '}
      {new Date().getFullYear()}
      {'.'}
      <br />
      <a style={{ color: '#fff', textDecoration: 'none', marginRight: '2em' }} href="/terms">Terms and conditions</a>
      <a style={{ color: '#fff', textDecoration: 'none' }} href="/privacy">Privacy policy</a>
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#fff"
    },
    info: {
      main: "#fff"
    }
  },
  typography: {
    fontFamily: ['Euclid Circular A']
  }
});

const renderer = ({ days, hours, minutes, seconds }) => { 
  return (
    <Grid xs={12} container={true} sx={{ display: 'flex', flexDirection: "row" }} key={`${days}-${seconds}`}>
      <Grid xs={2} item={true} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <Button sx={{ marginBottom: "1em" }} variant='text' color="info" disableElevation={true}>{days}</Button>
        Days
      </Grid>
      <Grid xs={1} item={true} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <Button sx={{ marginBottom: "1em" }}> : </Button>
      </Grid>
      <Grid xs={2} item={true} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <Button sx={{ marginBottom: "1em" }}  variant='text' color="info" disableElevation={true}>{hours}</Button>
        Hours
      </Grid>
      <Grid xs={2} item={true} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <Button sx={{ marginBottom: "1em" }}> : </Button>
      </Grid>
      <Grid xs={2} item={true} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <Button sx={{ marginBottom: "1em" }}  variant='text' color="info" disableElevation={true}>{minutes}</Button>
        Minutes
      </Grid>
      <Grid xs={1} item={true} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <Button sx={{ marginBottom: "1em" }}> : </Button>
      </Grid>
      <Grid xs={2} item={true} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <Button sx={{ marginBottom: "1em" }}  variant='text' color="info" disableElevation={true}>{seconds}</Button>
        Seconds
      </Grid>
    </Grid>
  )
}

export default function SignInSide() {
  const [ msg, setMsg ] = useState('');
  const handleClose = () => {
    setMsg('')
  }


  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const q = query(collection(db, "emails"), where("email", "==", data.get('email')));
      
      const querySnapshot = await getDocs(q);
      console.log(data.get('email'))
      if (querySnapshot.empty) {
        await addDoc(collection(db, 'emails'), { 
          email: data.get('email'),
          timestamp: serverTimestamp(),
        });
        
        setMsg('Thanks for adding your email. We will inform you when we will be ready to launch!')
      } else {
        setMsg('Looks like we have you already on the list. No worries! We will inform you when we will be ready to launch!')
      }
    } catch (err) {
      console.log(err);
      setMsg('Looks like something went wrong. Please come back later!')
    }
  };

  const theme = useTheme();
  const date = new Date('June 7, 2024 23:59:359')
  // console.log(date.getTime(), Date.now())

  return (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'black', margin: "-7px 0 0 0" }}>
                <SvgIcon><Logo /></SvgIcon>
              </Avatar>
              <Typography component="h1" variant="h5">
                Tripcompanion
              </Typography>
            </Box>
            <Typography component="h2" variant="h5">
              World's first travel discovery app! Sign up to be the first to experience and test the beta release of this travel innovation!
            </Typography>
            
              
            <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-around", marginTop: "4em", width: "100%" }}>
              <a target="_blank" style={{ textAlign: "center" }} href="https://apps.apple.com/app/tripcompanion-io/id6503609084">
                <AppStore width="80%" />
              </a>  
              <a target="_blank" style={{ textAlign: "center" }} href="https://play.google.com/store/apps/details?id=ai.tripcompanion.app&pli=1">
                <GooglePlay width="80%" />
              </a>
            </Box>
              
            {/* <Countdown date={Date.now() + 10000} /> */}
            <Box sx={{ flexDirection: "column", display: "flex", justifyContent: "space-around", minHeight: '40vh', verticalAlign: "middle" }}>
              <Typography sx={{ margin: 'auto', marginTop: "2em", marginBottom: "2em", textAlign: "center", width: { xs: '100%', lg: '60%', md: '80%' } }} component="h2" variant="h6">
                With this app in your pocket you instantly become a local, wherever in the world you are.
              </Typography>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>  
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex"
          }}
        ><img width="50%" src={iPhone} /></Grid>
      </Grid>
      <Snackbar
        open={msg.length != 0}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
    </>
  );
}
// export const Form = () => {
//   return (
//   <Box component="form" noValidate onSubmit={handleSubmit} sx={{ marginTop: "2em" }}>
//   <Grid container={true} spacing={useMediaQuery(theme.breakpoints.up('xs')) ? 2 : 0}>
//   <Grid item={true} xs={12} md={6}>
//     <TextField
//     InputProps={{sx: {borderRadius: { xs: "4px 0 0 4px", sm: "4px", md:"4px" } }}}
//     required
//     id="email"
//     label="Email Address"
//     name="email"
//     autoComplete="email"
//     autoFocus
//     fullWidth={useMediaQuery(theme.breakpoints.up('xs'))}
//   />
//   </Grid>
//   <Grid item={true} xs={12} md={6}>
//   <Button
//     variant="outlined"
//     disableElevation={true}
//     fullWidth={useMediaQuery(theme.breakpoints.up('xs'))}
//     sx={{ height: "100%", borderRadius: { xs: "0 4px 4px 0", sm: "4px", md: "4px" } }}
//     type='submit'
//   >
//     Join the waiting list
//   </Button>
//   </Grid>
//   </Grid>
//   <Grid xs={12} container={true}>
//     <Typography sx={{ marginTop: "2em", marginBottom: "2em", textAlign: "center", width: '100%' }} component="h2" variant="h4">
//       COMING SOON
//     </Typography>
//   </Grid>

//     <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-around" }}>
//     <a target="_blank" style={{ textAlign: "center" }} href="https://apps.apple.com/app/tripcompanion-io/id6503609084">
//       <AppStore width="80%" />
//     </a>  
//     <a target="_blank" style={{ textAlign: "center" }} href="https://play.google.com/store/apps/details?id=ai.tripcompanion.app&pli=1">
//       <GooglePlay width="80%" />
//     </a>
//     </Box>

//   {/* <Countdown date={Date.now() + 10000} /> */}
//   <Typography sx={{ margin: 'auto', marginTop: "2em", marginBottom: "2em", textAlign: "center", width: { xs: '100%', lg: '60%', md: '80%' } }} component="h2" variant="h6">
//     With this app in your pocket you instantly become a local, wherever in the world you are.
//   </Typography>
//   <Copyright sx={{ mt: 5 }} />
//   </Box>
//   )};