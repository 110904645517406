import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth, db } from "../firebase";
import { Button, TextField } from "@mui/material";
import { getDoc, doc } from "firebase/firestore";



const Login = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    
    const profileRef = doc(db, 'profiles', user.user.uid)
    const profile = (await getDoc(profileRef)).data();

    if (profile.role && profile.role === 'admin') {
      setUser(profile);
    }
    
    } catch (err) {
      
      console.log(err);
    }
  }

  const onEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }

  const onPasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  }

  return (<div>
    <TextField label="login" typ="email" onChange={e => onEmailChange(e)}/>
    <TextField label="password" type="password"  onChange={e => onPasswordChange(e)} />
    <Button onClick={handleLogin}>Login</Button>
  </div>)
}

export default Login;