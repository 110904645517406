import { Card, CardMedia, CardContent, Typography, CardActions, Button, Divider, Box } from "@mui/material";
import { query, collection, where, doc, onSnapshot } from 'firebase/firestore';
import { db, functions } from '../firebase'
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";

function OptionCard ({data, classification}) {
  const navigate = useNavigate();
  const { id, level, conversationId } = useParams();
  const [ product, setProduct] = useState();
  const theme = useTheme();
  


  useEffect(() => {
    // fetch data
    if (data && data.productId) {
      console.log('getting google')
      const getGoogleData = async () => {
        const q = query(collection(db, 'products'), 
          where("placeId", "==", data.productId)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const msgs = [];
          querySnapshot.forEach((doc) => {
              setProduct(doc.data());
          });
        });
      }
      getGoogleData();
    }
    // if (data && !data.enhanced) {
    //   const enhance = httpsCallable(functions, 'enhance')
    //   enhance(({ id: data.id, collection: data.questyType === 'option' ? 'options' : 'products' }));
    // }
  }, []);


  const getCorrectClassification = (topLevelClassification, classification) => {
    if (topLevelClassification.length && classification.length) {
      return topLevelClassification.length <= classification.length ? topLevelClassification : classification
    } else {
      return topLevelClassification.length ? topLevelClassification : classification;
    }
  }

  const handleLearnMore = async () => {
    if (data.type !== 'OPTION') {
      if (!data.high_end_details) {
        const getDetails = httpsCallable(functions, 'getDetails');
        getDetails({ id: data.productId })
      }
      navigate(`/${conversationId ? conversationId : data.conversationId}/details/${data.optionId}`);
      return;
    } else {
      if (data.updated) {
        navigate({ 
          pathname: `/${conversationId ? conversationId : data.conversationId}/options/${data.childrenPromptId}`
        })
      } else {
        const getOptions = httpsCallable(functions, 'analyzePrompt');
        const res = await getOptions({ prompt: '', conversationId, types: data.types, parent: data.optionId });

        navigate({ 
          pathname: `/${conversationId ? conversationId : data.conversationId}/options/${res.data.promptId}`
        })
      }
    }

  }
  // if (selectedMarker === googlePlace.place_id) {
  //   console.log('OPTIONCARD, SELECTED MARKER: ', selectedMarker, googlePlace.place_id);
  // }

  return (
    <Card sx={{ textAlign: "left", marginBottom: "1em", border: "1px solid #fff"}} elevation={0}>
      {product && product.photos ? 
      <CardMedia
        sx={{ height: 140, maxWidth: 300 }}
        image={product?.photos[0]?.src.small}
        title="green iguana"
      /> : <CardMedia sx={{ height: 140, backgroundColor: 'gray' }} title="placeholder">
        <Box sx={{ display: "flex", height: '100%', justifyContent: "center", padding: '10px', alignItems: 'center' }}>Looks like we couldn't find a photo of this</Box>
      </CardMedia>}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {data.type}
          
          <strong>Type: </strong>{data.type || 'no type'}
          <strong>Source: </strong>{data.source || 'no source'}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          {data.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {/* <Description classification={classification} data={data} /> */}
          {data.description}
        </Typography>
        <Divider></Divider>
        {data.destination?.type ? 
        <Typography variant="body2" color="text.secondary">
          <strong>Destination type: </strong>{data.destination.type}
        </Typography> : null }
        <Typography variant="body2" color="text.secondary">
          <strong>Classification: </strong>{product?.geometry.location.longitude}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleLearnMore}>Delete</Button>
      </CardActions>
    </Card>
  )
}

export default OptionCard;