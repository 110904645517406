import { Typography } from "@mui/material"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SvgIcon } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as Logo } from './logo-white.svg';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate } from "react-router-dom";

const Privacy = () => {
  const navigate = useNavigate();
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "2em", verticalAlign: "start" }}>
              <Link style={{ color: "#fff", textDecoration: 'none', display: 'flex', verticalAlign: "start", flexDirection: 'row'}} to="/" >
                <Avatar sx={{ m: 1, bgcolor: 'black', margin: "-7px 0 0 0" }}>
                  <SvgIcon><Logo /></SvgIcon>
                </Avatar>
                <Typography component="h1" variant="h5">
                  Tripcompanion
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
          <Typography variant="body2" style={{ margin: "0 2em 4em" }} color="text.secondary">
          <p>Privacy Policy for Tripcompanion</p>

<p>Effective Date: 18 Jul 2024</p>

<p>At Tripcompanion, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal data when you use our travel discovery app, Tripcompanion, ("App"). By accessing or using the App, you consent to the practices described in this policy.</p>

<p>Please read this Privacy Policy carefully to understand how we handle your personal information.</p>

<p>1. Information We Collect</p>

<p>1.1 Information You Provide: We may collect personal information that you voluntarily provide when you use the App, such as your name, email address, phone number, payment information, and travel preferences.</p>

<p>1.2 Automatically Collected Information: We may collect certain information automatically when you use the App, including your device's IP address, location information, device type, operating system, and usage data.</p>

<p>1.3 Cookies and Similar Technologies: Currently we do not use cookies. In future, we may use cookies and similar technologies to collect information about your browsing and app usage. You can manage your cookie preferences through your browser settings.</p>

<p>2. How We Use Your Information</p>

<p>2.1 To Provide Services: We use your personal information to recommend travel inspirations and to facilitate and process travel bookings, provide customer support, and improve our services.</p>

<p>2.2 Communication: We may send you transactional emails, service-related announcements, and promotional materials based on your communication preferences.</p>

<p>2.3 Analytics and Improvements: We analyse user behaviour and preferences to enhance the App's functionality, user experience, and overall performance.</p>

<p>3. Information Sharing</p>

<p>3.1 Service Providers: We may share your personal information with third-party service providers who assist us in providing and improving our services, including payment processors, data analytics providers, and customer support services.</p>

<p>3.2 Legal Compliance: We may disclose your information when required by law, such as to comply with a subpoena, legal process, or government request.</p>

<p>3.3 Business Transfers: In the event of a merger, acquisition, or sale of all or part of our assets, your personal information may be transferred to the acquiring entity.</p>

<p>4. Data Security</p>

<p>4.1 We implement reasonable security measures to protect your personal information from unauthorised access, disclosure, alteration, or destruction. However, no method of data transmission or storage is completely secure, and we cannot guarantee absolute security.</p>

<p>5. Your Choices and Rights</p>

<p>5.1 Access and Correction: You have the right to access, correct, update, or delete your personal information. You can do this by logging into your account or contacting us on info-support@tripcompanion.io</p>

<p>5.2 Marketing Communications: You can opt-out of receiving marketing communications from us by following the instructions provided in our emails or contacting us directly on info-support@tripcompanion.io</p>

<p>5.3 Do Not Track: Our App does not respond to "Do Not Track" signals from your browser.</p>

<p>6. Children's Privacy</p>

<p>6.1 The App is not intended for use by children under the age of 18. We do not knowingly collect personal information from children.</p>

<p>7. Changes to this Privacy Policy</p>

<p>7.1 We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will notify you of any material changes by posting the updated Privacy Policy on the App.</p>

<p>8. Contact Us</p>

<p>8.1 If you have questions or concerns about this Privacy Policy or our data practices, please contact us at info-support@tripcompanion.io</p>

<p>By using our App, you consent to the terms of this Privacy Policy and the collection, use, and sharing of your personal information as described herein.</p>

          </Typography>
        </Grid>
      </Grid>
  )
}

export default Privacy