import * as React from 'react';
import { useState } from 'react'; 
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Snackbar, SvgIcon } from '@mui/material';
import { ReactComponent as Logo } from './logo-white.svg'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Countdown from 'react-countdown';
import { db, auth } from './firebase';
import { collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import iPhone from './for-mobile-mockup-portrait.png'
import { Link, useNavigate } from "react-router-dom";


const ForgotPassword = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    debugger;
    try {
      debugger;
      const res = await sendPasswordResetEmail(auth, email);
      console.log(res);
      debugger;
    } catch (err) {
      console.log(err);
      debugger;
    }
    debugger;
  }
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "2em", verticalAlign: "start" }}>
            <Link style={{ color: "#fff", textDecoration: 'none', display: 'flex', verticalAlign: "start", flexDirection: 'row'}} to="/" >
              <Avatar sx={{ m: 1, bgcolor: 'black', margin: "-7px 0 0 0" }}>
                <SvgIcon><Logo /></SvgIcon>
              </Avatar>
              <Typography component="h1" variant="h5">
                Tripcompanion
              </Typography>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ marginTop: "2em" }}>
          <Grid container={true} spacing={useMediaQuery(theme.breakpoints.up('xs')) ? 2 : 0}>
            <Grid item={true} xs={12} md={6}>
              <TextField
                InputProps={{sx: {borderRadius: { xs: "4px 0 0 4px", sm: "4px", md:"4px" } }}}
                required
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                fullWidth={useMediaQuery(theme.breakpoints.up('xs'))}
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Button
                variant="outlined"
                disableElevation={true}
                fullWidth={useMediaQuery(theme.breakpoints.up('xs'))}
                sx={{ height: "100%", borderRadius: { xs: "0 4px 4px 0", sm: "4px", md: "4px" } }}
                type='submit'
              >
              Send
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword;